import { userApi, ancientApi, ossApi, orderApi, hemeraApi, acquisitionApi } from "@/util/axios";

var accToken = localStorage.getItem('login') && localStorage.getItem('login') != "undefined" && JSON.parse(localStorage.getItem('login')).access_token ? JSON.parse(localStorage.getItem('login')).access_token : ""
var userId = localStorage.getItem('login') && localStorage.getItem('login') != "undefined" && JSON.parse(localStorage.getItem('login')).user_id ? JSON.parse(localStorage.getItem('login')).user_id : ""

/**********************************登录相关*****************************************/
//登录
export function loginApi(data) {
    return userApi({
        url: `users/login`,
        method: "POST",
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 获取验证码
export function verApi(data) {
    return userApi({
        url: `sms_code`,
        method: "POST",
        data: {
            ...data,
            platform_type: 3
        },
        headers: {
            "Authorization": accToken
        }
    })
}

//选择身份
export function getidentityApi() {
    return ancientApi({
        url: 'user/identity',
        method: "GET",
        headers: {
            Authorization: accToken
        }
    })
}

//选择标签
export function getUserLabelApi() {
    return ancientApi({
        url: 'user/user_label',
        method: "GET",
        headers: {
            Authorization: accToken
        }
    })
}

//设置标签
export function setUserLabelApi(data) {
    return ancientApi({
        url: 'user/init_labels',
        method: "POST",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 查询用户信息
export function usersApi() {
    return userApi({
        url: "users/" + userId,
        method: "GET",
        params: {
            platform_type: 3
        },
        headers: {
            Authorization: accToken
        }
    })
}
// 查询用户信息
export function userJgApi() {
    return ancientApi({
        url: "index/get_institution",
        method: "GET",
        params: {
            platform_type: 3
        },
        headers: {
            Authorization: accToken
        }
    })
}

//查询是否存在标签
export function getLabelApi(userId, tokens) {
    return userApi({
        url: "users/" + userId,
        method: "GET",
        params: {
            platform_type: 3
        },
        headers: {
            Authorization: tokens
        }
    })
}

// 修改用户信息
export function editUsersApi(data) {
    return userApi({
        url: "users/" + userId,
        method: "PUT",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 获取vip协议
export function getvipProtocolApi(data) {
    return hemeraApi({
        url: "protocol",
        method: "get",
        params: {
            type: data.type
        },
        headers: {
            Authorization: accToken
        }
    })
}

// 获取banner
export function getBannerApi(type) {
    return hemeraApi({
        url: "banners?location_type=" + type,
        method: "get",
    })
}

// 安卓下载
export function getDownApi(type) {
    return hemeraApi({
        url: "versions",
        method: "get",
        params: {
            channel: type,
            dev_mode: 2
        },
    })
}

// 获取限免书单
export function getbookMianApi() {
    return ancientApi({
        url: "book_ku/free/book_list",
        method: "get",
    })
}

// 获取活动列表
export function getactivityListApi() {
    return ancientApi({
        url: "activity/list",
        method: "get",
    })
}

// 获取活动详情
export function getactivityInfoApi(id) {
    return ancientApi({
        url: "activity/info?activity_id=" + id,
        method: "get",
    })
}

// 领取优惠券
export function getachieveApi(id) {
    return ancientApi({
        url: "activity/achieve",
        method: "put",
        data: {
            coupon_id: id
        },
        headers: {
            "Authorization": accToken
        }
    })
}

export function basicsApi() {
    return ancientApi({
        url: `zk_ji/categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

export function bookDoctorApi(page, pagesize) {
    return ancientApi({
        url: `zk_ji/book/doctor`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

export function ywbookDoctorApi(page, pagesize) {
    return ancientApi({
        url: `book/doctor`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

export function wqbookDoctorApi(page, pagesize) {
    return ancientApi({
        url: `wq/book/doctor`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

export function unBindApi(data) {
    return userApi({
        url: `users/` + userId + "/un_bind",
        method: "put",
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

export function userVIPApi() {
    return ancientApi({
        url: `user/vip_goods`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

export function getcouponListApi(status) {
    return ancientApi({
        url: `activity/coupon`,
        method: "get",
        params: {
            status: status,
            platform_type: 3
        },
        headers: {
            "Authorization": accToken
        }
    })
}

export function selectcouponListApi(type, order_amount) {
    return ancientApi({
        url: `activity/change_coupon`,
        method: "get",
        params: {
            type: type,
            order_amount: order_amount,
            platform_type: 3
        },
        headers: {
            "Authorization": accToken
        }
    })
}

export function putExchangeApi(data) {
    return ancientApi({
        url: `activity/exchange`,
        method: "put",
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

export function loginOutApi() {
    return userApi({
        url: `users/logout`,
        method: "POST",
        data: {
            platform_type: "3"
        },
        headers: {
            "Authorization": accToken
        }
    })
}

/**********************************首页*****************************************/
// 推荐古籍
export function initBookApi(data) {
    return ancientApi({
        url: `index/popular_books`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 底部广告
export function advertListApi() {
    return ancientApi({
        url: `index/advert_list`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 热门搜索
export function hotsearchApi() {
    return ancientApi({
        url: `hot_word`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 搜索提示
export function searchTipsAPI(val) {
    return ancientApi({
        url: `index/search_tips`,
        method: "get",
        params: {
            platform_type: 3,
            keyword: val
        },
        headers: {
            "Authorization": accToken
        }
    })
}
// 搜索提示
export function keywordTipsApi(val) {
    return ancientApi({
        url: `index/keyword_tips?platform_type=3`,
        method: "get",
    })
}

// 为你推荐
export function popularBooksApi(page, page_size, type) {
    return ancientApi({
        url: `index/popular_books`,
        method: "get",
        params: {
            page: page,
            page_size: page_size,
            type: type
        },
        headers: {
            "Authorization": accToken
        }
    })
}

/**********************************普通搜索*****************************************/
//方剂搜索
export function fangSearchApi(data) {
    let extend = ''
    if (data.extend) {
        extend = data.extend
    }
    return ancientApi({
        url: `zk_fang/search`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

//医案搜索
export function anSearchApi(data) {
    let extend = ''
    if (data.extend) {
        extend = data.extend
    }
    return ancientApi({
        url: `zk_an/search`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

//搜索卡片
export function searchCardApi(data) {
    return ancientApi({
        url: `search_cards`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}
//古籍搜索
export function bookSearchApi(data) {
    let extend = ''
    if (data.extend) {
        extend = data.extend
    }
    return ancientApi({
        url: `index/search_books`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

//本草搜索
export function caoSearchApi(data) {
    let extend = ''
    if (data.extend) {
        extend = data.extend
    }
    return ancientApi({
        url: `zsk_bc/search`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

//经穴搜索
export function xueSearchApi(data) {
    let extend = ''
    if (data.extend) {
        extend = data.extend
    }
    return ancientApi({
        url: `zsk_jx/search`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

//名医搜索
export function yiSearchApi(data) {
    let extend = ''
    if (data.extend) {
        extend = data.extend
    }
    return ancientApi({
        url: `zsk_doctor/search`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

//百科搜索
export function keSearchApi(data) {
    return hemeraApi({
        url: `baike`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

//AI聊天
export function aitalkAPI(content) {
    return hemeraApi({
        url: `ai`,
        method: "POST",
        timeout: 50000,
        data: { user_id: userId, content: content },
        headers: {
            "Authorization": accToken
        }
    })
}

//同义词扩展
export function getExtendTimesApi() {
    return ancientApi({
        url: `user/get_extend_times?platform_type=3`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

/**********************************古籍相关*****************************************/

// 查询菜单
export function extraterritorialApi() {
    return ancientApi({
        url: `book/categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询菜单
export function wqBookApi() {
    return ancientApi({
        url: `wq/book/categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

export function basicsBookApi(data) {
    return ancientApi({
        url: `zk_ji/books`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

export function extratBookApi(data) {
    return ancientApi({
        url: `/books`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

export function extraterritorialBookApi(data) {
    return ancientApi({
        url: `wq/books`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询古籍
export function bookInfoApi(id) {
    return ancientApi({
        url: `zk_ji/book/info/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 古籍关键字查询
export function bookFilterApi(data) {
    return ancientApi({
        url: `book_filter`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询域外古籍
export function bookywInfoApi(id) {
    return ancientApi({
        url: `/book/info/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 基础古籍添加 笔记
export function jcbookywTagsApi(data) {

    return ancientApi({
        url: `/zk_ji/book/tags`,
        method: "POST",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 基础古籍添加 反馈
export function fnkuiApi(data) {

    return ancientApi({
        url: `/feedback`,
        method: "POST",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 基础古籍阅读记录
export function recordApi(data) {

    return ancientApi({
        url: `/user/record`,
        method: "POST",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 查询孤本古籍
export function bookgbInfoApi(id) {
    return ancientApi({
        url: `wq/book/info/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询域外古籍单独目录
export function bookgywMenuApi(id) {
    return ancientApi({
        url: `book/catalog/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询孤本古籍单独目录
export function bookggbMenuApi(id) {
    return ancientApi({
        url: `wq/book/catalog/` + id,
        method: "get", headers: {
            "Authorization": accToken
        }
    })
}

// 查询古籍评论列表
export function commentsListApi(id, order_type, type) {
    return ancientApi({
        url: `comments/` + id,
        method: "get",
        params: {
            order_type: order_type,
            type: type,
        },
        headers: {
            "Authorization": accToken
        }
    })
}

// 古籍发布评论列表
export function upCommentsListApi(data) {
    return ancientApi({
        url: `comment/` + data.target_id,
        method: "POST",
        data: { "content": data.content, "type": data.type },
        headers: {
            "Authorization": accToken
        }
    })
}

// 删除评论
export function delCommentApi(data) {
    return ancientApi({
        url: 'comments',
        method: 'delete',
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 删除回答
export function delQuestionApi(id, answer_id) {
    return ancientApi({
        url: 'community/' + id + '/answer',
        method: 'delete',
        data: {
            answer_id: answer_id
        },
        headers: {
            "Authorization": accToken
        }
    })
}


/**********************************知识库相关*****************************************/
// 查询知识库科室目录
export function departmentsApi() {
    return ancientApi({
        url: `zk_an/departments`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库科室目录
export function getknowledgeGraphAPI(id) {
    return ancientApi({
        url: `zk_fang/knowledge_graph/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库科室目录
export function likeknowledgeGraphAPI(id, data) {
    return ancientApi({
        url: 'user/knowledge_graph_agree/' + id + '?platform_type=3',
        method: 'post',
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库医生目录
export function doctorsApi() {
    return ancientApi({
        url: `zk_an/doctors`,
        method: "get", headers: {
            "Authorization": accToken
        }
    })
}

// 查询某名家或科室的详情列表
export function digitMdeApi(data) {
    return ancientApi({
        url: `zk_an/cases`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询某名家或功效的详情列表
export function prescriptionsApi(data) {
    return ancientApi({
        url: `zk_fang/prescriptions`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询某名家或科室的详情
export function digitMdeDetailApi(id) {
    return ancientApi({
        url: `zk_an/case/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库名医按朝代列表
export function prescriptionApi() {
    return ancientApi({
        url: `zsk_doctor/categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库名医详情
export function doctorDetailApi(id) {
    return ancientApi({
        url: `zsk_doctor/detail/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库本草列表
export function categorieslApi() {
    return ancientApi({
        url: `zsk_bc/categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库本草详情
export function categorieslDetailApi(id) {
    return ancientApi({
        url: `zsk_bc/detail/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库经穴列表
export function jxcategorieslApi() {
    return ancientApi({
        url: `zsk_jx/categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库本草详情
export function jxcategorieslDetailApi(id) {
    return ancientApi({
        url: `zsk_jx/detail/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}


// 查询知识库方剂功效接口
export function fjgxcategorieslApi() {
    return ancientApi({
        url: `zk_fang/categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库方剂名医接口
export function fjmycategorieslApi() {
    return ancientApi({
        url: `zk_fang/doctors`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库方剂名医接口
export function fjljcategorieslApi() {
    return ancientApi({
        url: `zk_fang/lj_categories`,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 查询知识库本草详情
export function fangcategorieslDetailApi(id) {
    return ancientApi({
        url: `zk_fang/fang/` + id,
        method: "get",
        headers: {
            "Authorization": accToken
        }
    })
}

// 知识库划词搜索
export function wordBaikeApi(keyword) {
    return ancientApi({
        url: `word_baike`,
        method: "get",
        params: {
            keyword: keyword,
        },
        headers: {
            "Authorization": accToken
        }
    })
}

// 知识库划词搜索
export function delTagApi(data) {
    return ancientApi({
        url: `zk_ji/book/tag`,
        method: "DELETE",
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

/**********************************中医圈相关*****************************************/
// 中医圈我要提问接口
export function postquestionApi(data) {

    return ancientApi({
        url: `community/question`,
        method: "POST",
        data: {
            title: data.title,
            content: data.content
        },
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈获取提问接口
export function getquestionApi(data) {
    return ancientApi({
        url: `community/questions`,
        method: "get",
        params: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈为你推荐（古籍）
export function getRecommendBooksApi(data) {
    return ancientApi({
        url: `community/get_recommend_books`,
        method: "get",
        params: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈为你推荐（问题）
export function getQuestionstuiApi() {
    return ancientApi({
        url: `hot/questions`,
        method: "get",
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈我要回答接口
export function postcommentApi(data) {

    return ancientApi({
        url: `community/` + data.question_id + `/answer`,
        method: "POST",
        data: {
            content: data.content,
        },
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈点赞接口
export function starApi(data) {
    return ancientApi({
        url: `community/star`,
        method: "post",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈取消点赞接口
export function delstarApi(data) {
    return ancientApi({
        url: `community/star`,
        method: "put",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈获取评论接口
export function pinglunApi(data) {
    return ancientApi({
        url: `community/comments`,
        method: "get",
        params: data,
        headers: {
            Authorization: accToken
        }
    })
}

export function replyApi(id, data) { //中医圈回复
    return ancientApi({
        url: '/community/reply/' + id,
        method: "post",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈发表评论接口
export function fapinglunApi(data) {
    return ancientApi({
        url: `community/comments`,
        method: "post",
        data: data,
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈回答详情接口
export function answerInfoApi(questionId) {
    return ancientApi({
        url: `community/question/` + questionId,
        method: "get",
        headers: {
            Authorization: accToken
        }
    })
}

// 中医圈回答详情列表接口
export function answerListApi(questionId) {

    return ancientApi({
        url: `community/` + questionId + `/answers`,
        method: "get",
        headers: {
            Authorization: accToken
        }
    })

}

// 中医圈上传图片接口
export function ossAspi(data) {
    return ossApi({
        url: `upload`,
        method: "POST",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

/**********************************个人资料相关*****************************************/
// 收藏列表接口
export function collectListApi(data) {

    return ancientApi({
        url: `collects`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 收藏接口
export function collectApi(data) {

    return ancientApi({
        url: `collect`,
        method: "POST",
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 取消收藏接口
export function cancelCollectApi(data) {

    return ancientApi({
        url: `collects`,
        method: "DELETE",
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 我的书架接口
export function collectBooksApi(data) {
    return ancientApi({
        url: `collect_books`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 我的动态接口
export function dynamicApi(data) {
    return ancientApi({
        url: `news`,
        method: "get",
        params: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 已购资源接口
export function buyApi(data) {
    return userApi({
        url: `users/purchased_list/` + userId,
        method: "get",
        params: {
            page: data.page,
            page_size: data.pagesize,
            platform_type: 3,
        },
        headers: {
            "Authorization": accToken
        }
    })
}

/**********************************订单相关*****************************************/
// 统一下单接口
export function placeOrder(data) {
    return orderApi({
        url: `users/` + userId + `/order`,
        method: "post",
        data: data,
        headers: {
            "Authorization": accToken
        }
    })
}

// 统一下单接口
export function getInquireData(data) {
    let userId = JSON.parse(localStorage.getItem('login')).user_id ? JSON.parse(localStorage.getItem('login')).user_id : ""
    return orderApi({
        url: `users/` + userId + `/order`,
        method: "get",
        params: {
            out_trade_no: data.out_trade_no,
            channel: 'web',
            platform_type: 3,
        },
        headers: {
            "Authorization": accToken
        }
    })
}



/**********************************数据采集*****************************************/
export function assemblyUp(data) {
    return acquisitionApi({
        url: `assembly_data_up`,
        method: "post",
        data: data,
    })
}
export function commonUp(data) {
    return acquisitionApi({
        url: `common_data_up`,
        method: "post",
        data: data,
    })
}
export function getpages() {
    return acquisitionApi({
        url: `page_config?platform_type=2`,
        method: "get",
    })
}